import React from 'react'

import Layout from '../components/layout'

import './service.scss'

function Services() {
  return (
    <Layout>
      <section>
        <div className="service-header-row">OUR VALUES</div>
        <div>
          SPEED - We know that your equipment is your lively hood. We prioritize
          speed to ensure you are not out of your equipment longer than is
          needful when repair is required
        </div>
        <div>
          THOROUGHNESS - We clean and repair your equipment through ESAB-trained
          processes to ensure your equipment functions and looks like-new upon
          return
        </div>
        <div>
          AFFORDABILITY - Your equipment is expensive! Repairing with us cost
          half the price of buying new equipment, saving you and your company
          cash to invest in other parts of your business
        </div>
      </section>
      <section>
        <div className="service-header-row">OUR PROCESS IN ACTION</div>
        <div className="service-body-row">
          <div className="service-body-section"></div>
          <div className="service-body-section"></div>
        </div>
        <div className="service-body-row"></div>
        <div className="service-body-row"></div>
        <div className="service-body-row"></div>
      </section>
    </Layout>
  )
}

export default Services
